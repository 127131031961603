import { useState } from 'react'
import classNames from 'classnames'

export const FieldTextBox = ({
  fieldId,
  label,
  required,
}: {
  fieldId: string
  label: string
  required: boolean
}) => {
  const [valid, setValid] = useState(true)
  return (
    <div
      className={classNames('c-form__item', {
        'c-form__item--mandatory': required,
        'c-form__error': !valid,
      })}>
      <label htmlFor={fieldId}>{label}</label>
      <input
        type="text"
        id={fieldId}
        name={fieldId}
        required={required}
        onInvalid={() => setValid(false)}
        onInput={() => setValid(true)}
      />
    </div>
  )
}
