import { useUserSettings } from '@dustin-web/microsite-components/src/hooks/use-user-settings'
import { Language } from '@dustin-web/microsite-config'
import Script from 'next/script'

const SITE_KEY = '6LcXMQoUAAAAAM_4J9H9zzCXhriLLWYVRpnXF0dB'

export const renderCaptcha = (elementId: string) => {
  const widgetId: number = (window as any).grecaptcha.render(elementId, {
    sitekey: SITE_KEY,
  })

  return widgetId
}

export const getCaptchaResponse = (widgetId: number) => {
  const response: string = (window as any).grecaptcha.getResponse(widgetId)

  const isValid = !!response

  return { response, isValid }
}

export const RecaptchaScript = () => {
  const { language } = useUserSettings()
  return (
    <Script
      src={`https://www.google.com/recaptcha/api.js?hl=${getCaptchaLanguageCode(
        language
      )}&render=explicit`}
    />
  )
}

const getCaptchaLanguageCode = (language: Language) => {
  // https://developers.google.com/recaptcha/docs/language

  switch (language) {
    case 'Swedish':
      return 'sv'
    case 'Norwegian':
      return 'no'
    case 'Danish':
      return 'da'
    case 'Finnish':
      return 'fi'
    case 'Dutch':
      return 'nl'
    default:
      return 'en'
  }
}
