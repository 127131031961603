import { useState } from 'react'
import classNames from 'classnames'

export const FieldPredefinedValueSelection = ({
  fieldId,
  label,
  required,
  allowMultipleSelections,
  options,
}: {
  fieldId: string
  label: string
  required: boolean
  allowMultipleSelections: boolean
  options?: string[] | null
}) => {
  const [valid, setValid] = useState(true)
  const mappableOptions = options ?? []
  return (
    <div
      className={classNames('c-form__item', {
        'c-form__item--mandatory': required,
        'c-form__error': !valid,
      })}>
      <label htmlFor={fieldId}>{label}</label>
      {allowMultipleSelections ? (
        <select
          id={fieldId}
          name={fieldId}
          size={5}
          multiple
          required={required}
          onInvalid={() => setValid(false)}
          onInput={() => setValid(true)}>
          {mappableOptions.map(option => (
            <option key={option}>{option}</option>
          ))}
        </select>
      ) : (
        <select
          id={fieldId}
          name={fieldId}
          onInvalid={() => setValid(false)}
          onInput={() => setValid(true)}>
          {mappableOptions.map(option => (
            <option key={option}>{option}</option>
          ))}
        </select>
      )}
    </div>
  )
}
