import {
  GetFormQuery,
  SubmitFormMutation,
  SubmitFormMutationVariables,
} from 'src/graphql/generated/graphql-types'
import { useTranslations } from '@dustin-web/microsite-components/src/hooks/use-translations'
import { FormEvent, useEffect, useState } from 'react'
import { useAuth } from '@dustin-web/microsite-components/src/auth'
import classNames from 'classnames'
import { Field } from './field'
import { renderCaptcha, getCaptchaResponse, RecaptchaScript } from './recaptcha'
import { SubmitForm } from 'src/graphql/submit-form.graphql'
import { useGraphqlMutation } from '@dustin-web/microsite-data/src/hooks/use-graphql'

type FormType = NonNullable<GetFormQuery['customerServiceInquiry']>
type Props = { id: number; form: FormType; onClose?: () => void }

type Field = NonNullable<GetFormQuery['customerServiceInquiry']>['fields'][0]

export const Form = ({ id, form, onClose: onClose }: Props) => {
  const { translate } = useTranslations()
  const { isAuthenticated } = useAuth()

  const [captchaInitialized, setCaptchaInitialized] = useState(false)
  const [captchaValid, setCaptchaValid] = useState(true)
  const [captchaWidgetId, setCaptchaWidgetId] = useState(0)
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [errorCode, setErrorCode] = useState('')
  const [submitting, setSubmitting] = useState(false)

  const [submitFormMutation] = useGraphqlMutation<SubmitFormMutation, SubmitFormMutationVariables>(
    SubmitForm,
    {
      onError: error => {
        const errorCode =
          (error?.response?.errors?.at(0)?.extensions['code'] as string) ?? 'UNKNOWN_ERROR'
        setErrorCode(errorCode)
      },
    }
  )

  const submit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setFormSubmitted(false)
    setErrorCode('')
    setSubmitting(true)

    const target = event.target as typeof event.target & { elements: HTMLFormControlsCollection }
    const elements = target.elements

    const valueFields = form.fields.map((f, index) => {
      const elementText = elements[index] as HTMLInputElement
      const elementSelect = elements[index] as HTMLSelectElement

      const values = elementSelect?.selectedOptions
        ? Array.from(elementSelect.selectedOptions).map(o => o.value)
        : [elementText!.value]

      return {
        label: f.label,
        values,
        zendeskId: f.zendeskId,
        isDropdownField: !!elementSelect?.selectedOptions || false,
      }
    })

    if (isAuthenticated && submitFormMutation) {
      await submitFormMutation({
        variables: { id, title: form.title, fields: valueFields },
      })
      setFormSubmitted(true)
    } else {
      const { isValid, response: captchaResponse } = getCaptchaResponse(captchaWidgetId)

      setCaptchaValid(isValid)
      if (isValid && submitFormMutation) {
        await submitFormMutation({
          variables: { id, title: form.title, fields: valueFields, captchaResponse },
        })

        setFormSubmitted(true)
      } else {
        setErrorCode('INVALID_CAPTCHA')
      }
    }

    setSubmitting(false)
  }

  useEffect(() => {
    if (!formSubmitted && !isAuthenticated && !captchaInitialized) {
      initCaptcha()
    }
  }, [])

  const initCaptcha = () => {
    setTimeout(() => {
      const widgetId = renderCaptcha(`recaptcha-${id}`)
      setCaptchaWidgetId(widgetId)
      setCaptchaInitialized(true)
    }, 500)
  }

  if (formSubmitted && !errorCode) {
    return (
      <div>
        <div>
          <p>{translate('Form_SentSuccessfully')}</p>
        </div>
        {onClose && (
          <div className="c-form__item">
            <button type="button" className="c-btn c-btn--primary" onClick={() => onClose()}>
              {translate('Form_Ok')}
            </button>
          </div>
        )}
      </div>
    )
  }

  return (
    <form onSubmit={submit} className="c-form" method="GET">
      {form.fields.map((field, index) => (
        <Field field={field} index={index} key={index} />
      ))}
      {!isAuthenticated && (
        <div
          className={classNames('c-form__item', {
            'c-form__group-error': !captchaValid,
          })}>
          <RecaptchaScript />
          {!captchaInitialized && <div style={{ width: 304, height: 78 }} />}
          <div id={`recaptcha-${id}`}></div>
        </div>
      )}
      {errorCode && (
        <div>
          <div className="u-py-16 u-px-16 u-bg-light-red u-border u-border-light d-block u-text-center u-mb-24">
            {translate(
              errorCode === 'INVALID_CAPTCHA' ? 'Form_InvalidCaptcha' : 'Form_GenericError'
            )}
          </div>
        </div>
      )}
      <div className="c-form__item">
        <button type="submit" className="c-btn c-btn--primary" disabled={submitting}>
          {translate('Form_Send')}
        </button>
        {onClose && (
          <button type="button" className="c-btn" onClick={() => onClose()} disabled={submitting}>
            {translate('Form_Cancel')}
          </button>
        )}
      </div>
    </form>
  )
}
