import { useState } from 'react'
import classNames from 'classnames'

export const FieldTextArea = ({
  fieldId,
  label,
  required,
  rows,
}: {
  fieldId: string
  label: string
  required: boolean
  rows: number
}) => {
  const [valid, setValid] = useState(true)
  return (
    <div
      className={classNames('c-form__item', {
        'c-form__item--mandatory': required,
        'c-form__error': !valid,
      })}>
      <label htmlFor={fieldId}>{label}</label>
      <textarea
        id={fieldId}
        name={fieldId}
        required={required}
        rows={rows}
        onInvalid={() => setValid(false)}
        onInput={() => setValid(true)}></textarea>
    </div>
  )
}
