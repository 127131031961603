import { GetFormQuery, QueryFieldType } from 'src/graphql/generated/graphql-types'
import { FieldTextBox } from './field-textbox'
import { FieldTextArea } from './field-textarea'
import { FieldPredefinedValueSelection } from './field-predefinedvalueselection'

type FieldType = NonNullable<GetFormQuery['customerServiceInquiry']>['fields'][0]

export const Field = ({ field, index }: { field: FieldType; index: number }) => {
  const fieldId = `field_${index}`

  switch (field.type) {
    case QueryFieldType.TextBox:
      return <FieldTextBox fieldId={fieldId} label={field.label} required={field.required} />
    case QueryFieldType.TextArea:
      return (
        <FieldTextArea
          fieldId={fieldId}
          label={field.label}
          required={field.required}
          rows={field.rows}
        />
      )
    case QueryFieldType.PredefinedValueSelection:
      return (
        <FieldPredefinedValueSelection
          fieldId={fieldId}
          label={field.label}
          required={field.required}
          allowMultipleSelections={field.allowMultipleSelections}
          options={field.options}
        />
      )
    default:
      console.error(`Unknown field type: ${field.type}`)
      return null
  }
}
